<template>
  <b-modal
    id="modal-apartment"
    ref="refModal"
    :title="t('Dự án/Tòa nhà')"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t('Tên tòa nhà') }} <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder="Resident Apartment 68"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="shortName"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t('Tên viết tắt/Mã tòa') }}
                </template>
                <b-form-input
                  id="shortName"
                  v-model="itemLocal.shortName"
                  placeholder="CH-01"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>

          <!-- Địa chỉ -->
          <b-col
            cols="12"
            class="mt-2"
          >
            <h5>{{ t('Thông tin địa chỉ') }}</h5>
          </b-col>
          <b-col
            v-if="isEnableLocationModule()"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="location"
              rules="required"
            >
              <select-location
                v-model="itemLocal.location"
                :required="true"
                :default-value="itemLocal.location"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="province"
              rules="required"
            >
              <select-province
                v-model="itemLocal.province"
                required
                :default-value="itemLocal.province"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="district"
              rules="required"
            >
              <select-district
                v-model="itemLocal.district"
                required
                :default-value="itemLocal.district"
                :province="itemLocal.province"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="ward"
              rules="required"
            >
              <select-ward
                v-model="itemLocal.ward"
                required
                :default-value="itemLocal.ward"
                :district="itemLocal.district"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="address"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t('Địa chỉ chi tiết') }} <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="address"
                    v-model="itemLocal.address"
                    placeholder="91 Nguyễn Chí Thanh"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Active/Inactive -->
          <b-col

            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.active"
                name="check-button"
                switch
                inline
              >
                {{ t('Hoạt động') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>
        <b-row v-if="allowContractPaymentDayConfig">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Ngày thanh toán"
              rules="required|minValue:1|maxValue:31"
            >
              <number-input
                v-model="itemLocal.paymentDay"
                required
                :default-value="itemLocal.paymentDay"
                :label="t('Ngày thanh toán')"
                placeholder="5"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>

        <b-row>

          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. {{ t('Dịch vụ tòa nhà') }}
              </div>
              <b-button
                v-b-modal.modal-list-service
                variant="primary"
                class="btn-icon ml-auto"
                size="sm"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <b-list-group class="">
              <b-list-group-item
                v-for="(eachFee, index) in itemLocal.fees"
                :key="eachFee.id"
              >
                <b-row>
                  <b-col class="font-weight-bolder">
                    {{ index + 1 }}. {{ eachFee.name }}
                  </b-col>
                  <b-col class=" ">
                    {{ eachFee.price > 0 ? Number(eachFee.price).toLocaleString() : "---" }}  {{ eachFee.unit && eachFee.unit.length > 0 ? `đ/${eachFee.unit}` : "" }}
                  </b-col>
                  <b-col>
                    <b-button
                      variant="outline-danger"
                      class="btn px-1 btn-outline-danger ml-1 ml-sm-auto float-sm-right mr-sm-1 w-90 min-w-75"
                      @click="removeFee(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ t('Xoá') }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>

            </b-list-group>
          </b-col>
        </b-row>
        <b-row v-if="enableTingee">
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                3. {{ t('Tài khoản gạch nợ tự động') }}
              </div>

            </div>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="tingee"
              rules="required"
            >
              <select-tingee-account
                v-model="itemLocal.tingee"
                required
                :default-value="itemLocal.tingee"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <select-invoice-template
              v-model="itemLocal.invoiceTemplate"
              :default-value="itemLocal.invoiceTemplate"
              :label="t('Mẫu in hóa đơn')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <select-contract-template
              v-model="itemLocal.contractTemplate"
              :default-value="itemLocal.contractTemplate"
              :label="t('Mẫu hợp đồng')"
            />
          </b-col>
        </b-row>
        <b-row v-if="itemLocal && itemLocal.id > 0">
          <b-col cols="12">
            <select-multiple-cashbook
              v-model="itemLocal.cashbooks"
              :label="t('Tài khoản ngân hàng')"
              required
              :default-value="itemLocal.cashbooks"
              class="select-cashbook"
            />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
    <list-service-modal
      :fees-selected="itemLocal.fees"
      @selectService="onAddServices"
    />

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BInputGroupPrepend,
  BButton,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, minValue, maxValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectProvince from '@/views/components/SelectProvince.vue';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectWard from '@/views/components/SelectWard.vue';
import SelectLocation from '@/views/components/SelectLocation.vue';
import { isEnableLocationModule } from '@/auth/utils';
import NumberInput from '@/views/components/NumberInput.vue';
import ListServiceModal from '@/views/contract/contract/listServiceModal/ListServiceModal.vue';
import SelectTingeeAccount from '@/views/components/SelectTingeeAccount.vue';
import SelectInvoiceTemplate from '@/views/components/SelectInvoiceTemplate.vue';
import SelectContractTemplate from '@/views/components/SelectContractTemplate.vue';
import SelectMultipleCashbook from '@/views/components/SelectMultipleCashbook.vue';
import useApartmentModal from './useApartmentModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BCol,
    BRow,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BListGroup,
    BListGroupItem,
    SelectLocation,
    SelectProvince,
    SelectDistrict,
    SelectWard,
    NumberInput,
    ListServiceModal,
    SelectTingeeAccount,
    SelectInvoiceTemplate,
    SelectContractTemplate,
    SelectMultipleCashbook,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    apartmentId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      required,
      minValue,
      maxValue,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      isSubmitting,
      isTingTong,
      onSubmit,
      onAddServices,
      onOpen,
      removeFee,
      isLoading,
      enableTingee,
      t,
      allowContractPaymentDayConfig,
    } = useApartmentModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      isTingTong,
      enableTingee,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      isEnableLocationModule,
      onAddServices,
      onOpen,
      removeFee,
      isLoading,
      allowContractPaymentDayConfig,
      t,
    };
  },
};
</script>
