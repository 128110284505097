import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmptyRooms(ctx, params) {
      return useJwt.getReportEmptySlots(params).then(response => response);
    },
    exportEmptyRooms(ctx, params) {
      return useJwt.exportReportEmptySlots(params).then(response => response);
    },
    fetchNearEmptyRooms(ctx, params) {
      return useJwt.getReportNearEmptySlots(params).then(response => response);
    },
    exportNearEmptyRooms(ctx, params) {
      return useJwt.exportReportNearEmptySlots(params).then(response => response);
    },
    getRoomDetail(ctx, id) {
      return useJwt.getRoomDetail(id).then(response => response);
    },
    fetchSpecialContracts(ctx, params) {
      return useJwt.getReportSpecialContracts(params).then(response => response);
    },
    exportSpecialContracts(ctx, params) {
      return useJwt.exportReportSpecialContracts(params).then(response => response);
    },
  },
};
